import React from 'react';
import langConfig from "../configuration/lang.json"

export const AccessibleScreen = ({active, action, lang}) => {


    return (
        (active == true)?(
            <div className="full w-full flex justify-center m-auto absolute left-0 right-0 items-center min-h-[85vh] flex-col gap-9">
                <img src="/bg/notAccessible.svg" className="w-3/4 max-w-[500px]" alt={langConfig[lang].notAccessibleDivision} />
                <p className="font-[Almarai]">{langConfig[lang].notAccessibleDivision}</p>
                <button
                    onClick={action}
                    className={`bg-[#151048] text-white border-0 font-[Almarai] rounded-full text-md  py-3 my-4 px-10 w-fit m-auto`}
                >{langConfig[lang].gotYourToDivistion}</button>
            </div>
        ):''
    );


}