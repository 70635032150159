/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  BackNavBar,
  StartExam,
  Modal,
  Pagination,
  Controllers,
  MCQ,
  Essay,
  Paragraph,
  FullReport,
  EssayModal,
  Timer,
  EssayEvaluation,
  Login,
  AccessibleScreen,
  Title,
  ResizeImage,
} from "../components";
import {
  setBlur,
  setModal,
  setExamCounter,
  setBriefReport,
  setModalBody,
  setModalTitle,
  setModalBotton,
  setTimer,
  setExamConfig,
  setStartExam,
  setModalSubTitle,
  setUserAnswer,
  setBookmark,
  setQuestionsList,
  setCurrentQues,
  setFullReport,
  setAct,
  setActualNumber,
} from "../store/actions";
import config from "../sql/config.json";
import { v4 as uuidv4 } from "uuid";
import { useIsAuthenticated } from "react-auth-kit";
import { books } from "../data";


export const Exam = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const ae = require("../data/exams/" + params.id + ".json");
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  const [exams, setExams] = useState(ae);
  const {
    lang,
    currentQues,
    startExam,
    blur,
    modal,
    examCounter,
    briefReport,
    fullReport,
    userAnswer,
    actualNumber,
    essayModal,
    examConfig,
  } = useSelector((state) => state.userAppReducer);
  const [questions, setQuestions] = useState(exams);
  const [isLoading, setIsLoading] = useState(true);
  const [reportMode, setReportMode] = useState(window.location.search);
  const [fullScoreMain, setFullScoreMain] = useState(0);
  const [fullUserScore, setFullUserScore] = useState(0);
  const [userIfAnswer, setUserIfAnswer] = useState(false);
  const [modelEssayEvaluation, setModelEssayEvaluation] = useState(false);
  const [activeLogin, setActiveLogin] = useState(
    isAuthenticated() === false &&
      localStorage.getItem("userLogin") &&
      localStorage.getItem("userLogin") === false
      ? true
      : false
  );
  const [accessable, setAccessable] = useState(false);
  const [images, setImages] = useState([]);
  const [currentDivision, setCurrentDivision] = useState(localStorage.getItem("division"));
  const [subject, setSubject] = useState(
    books.filter(
      (book) =>
        book.id === parseInt(params.subjectID) &&
        (book.division ===
          currentDivision.split("/")[1].toString() ||
          book.division === "all")
    )[0]
  );

  useEffect(() => {
    checkAccessible();
    dispatch(setBlur(true));
    dispatch(setBriefReport(false));
    dispatch(setFullReport(false));
    dispatch(setCurrentQues(0));
    dispatch(setStartExam(false));
    dispatch(setModal(false));
    dispatch(setQuestionsList(exams.questions));
    dispatch(
      setUserAnswer(
        localStorage.getItem("userAnswer_" + params.id) !== null
          ? JSON.parse(localStorage.getItem("userAnswer_" + params.id))
          : []
      )
    );
    dispatch(
      setTimer(
        localStorage.getItem("userTime_" + params.id) !== null
          ? localStorage.getItem("userTime_" + params.id) - exams.duration
          : exams.duration
      )
    );

    // @ts-ignore
    window.bookmark = [];
    // @ts-ignore
    window.report = [];
    // @ts-ignore
    window.time = exams.duration;
    let a = localStorage.getItem("userTime_" + params.id);
    let b = exams.duration;
    if (a > b) {
      localStorage.setItem("userTime_" + params.id, 0);
    }
    // @ts-ignore
    // window.userTime = (localStorage.getItem("userAnswer_" + params.id)) ? parseInt(localStorage.getItem("userTime_" + params.id)) : 0;
    setUserIfAnswer(
      localStorage.getItem("userAnswer_" + params.id)
        ? localStorage.getItem("userAnswer_" + params.id).length
        : 0
    );
    startTimerMain();
    // @ts-ignore
    dispatch(setExamCounter(questions.questions.length));
    if (userAnswer.length <= 0 || briefReport == true || fullReport == true) {
      // @ts-ignore
      dispatch(setModal(false));
      dispatch(setStartExam(true));
      dispatch(setBriefReport(false));
      dispatch(setFullReport(false));
    } else {
      // @ts-ignore
      dispatch(setStartExam(false));
      // @ts-ignore
      dispatch(setModalBotton([...buttonsListForModelFinish]));
      // @ts-ignore
      dispatch(setModalTitle(""));
      // @ts-ignore
      dispatch(setModalSubTitle(config.config[exams.lang].takenBody));
      // @ts-ignore
      dispatch(setModalBody(""));
      // @ts-ignore
      dispatch(setBlur(true));
      // @ts-ignore
      dispatch(setModal(true));
    }
    // @ts-ignore
    dispatch(setExamConfig(questions));
    setTimeout(() => {
      // @ts-ignore
      setIsLoading(document.readyState === "complete" ? 0 : 1);
    }, 100);
    if (params.index) {
      dispatch(setModal(false));
      dispatch(setStartExam(false));
      dispatch(setBriefReport(false));
      dispatch(setBlur(false));
      dispatch(setCurrentQues(parseInt(params.index) - 1));
    }
    
  }, [userIfAnswer]);

  const checkAccessible = () => {
    try {
      if (
        localStorage.getItem("division") ===
        `${subject.school}/${subject.division}`
      ) {
        setAccessable(true);
      } else {
        console.log(subject.school,subject.division,localStorage.getItem("division").split("/"));
        if (
          subject.school === "all" ||
          subject.division === localStorage.getItem("division").split("/")[1]
        ) {
          setAccessable(true);
        } else {
          setAccessable(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const startTimeFrom = () => {
    window.timer = true;
  };

  const closeModel = () => {
    // @ts-ignore
    dispatch(setBlur(false));
    // @ts-ignore
    dispatch(setModal(false));
    if (exams.timer === true) {
      startTimeFrom();
    }
  };

  const buttonsListForModelFinish = [
    {
      text: config.config[exams.lang].complete,
      color: "text-white", // tail wind css color
      border: "border", //tail wind css border
      borderColor: "border-[#151048]", //tail wind css border
      background: "bg-[#151048]",
      action: closeModel,
    },
    {
      text: config.config[exams.lang].Retake,
      color: "text-[#151048]", // tail wind css color
      border: "border", //tail wind css border
      borderColor: "border-gray-300", //tail wind css border
      background: "bg-gray-300",
      action: () => {
        localStorage.removeItem("userTime_" + params.id);
        localStorage.removeItem("userAnswer_" + params.id);
        window.location.reload(false);
      },
    },
  ];

  const checkBookMark = () => {
    // @ts-ignore
    if (window.report.length > 0) {
      // @ts-ignore
      setExams(window.report[0]);
      // @ts-ignore
      setQuestions(window.report[0]);
      // @ts-ignore
      dispatch(setExamCounter(window.report[0].questions.length));
      // @ts-ignore
      dispatch(setUserAnswer(window.report[0].userAnswer));
      // @ts-ignore
      window.userTime = window.report[0].userTime;

      // @ts-ignore
      dispatch(setExamConfig(window.report[0]));

      // @ts-ignore
      dispatch(setBlur(false));

      // @ts-ignore
      dispatch(setModal(false));

      // @ts-ignore
      dispatch(setBriefReport(true));

      // @ts-ignore
      dispatch(setStartExam(false));

      setIsLoading(false);
    } else {
      setTimeout(() => {
        checkBookMark();
      }, 1500);
    }
  };

  const ParagraphAnswers = (list) => {
    const item = [];
    list.map((e, index) => {
      let a = {
        currentQues: currentQues,
        QuesID: e.QuesID,
        id: e.id,
        lang: exams.lang,
        QuesType: e.QuesType,
        QuesSubType: e.QuesSubType,
        QuesScore: e.QuesScore,
        QuesHeadTxt: e.QuesHeadTxt,
        QuesTxt: e.QuesTxt.replaceAll(
          "image/inlineImages",
          window.location.pathname.split("index.html")[0] + "image/inlineImages"
        ),
        QuesShowAns1:
          e.QuesType !== "Paragraph"
            ? {
                txt: e.QuesShowAns1.txt.replaceAll(
                  "image/inlineImages",
                  window.location.pathname.split("index.html")[0] +
                    "image/inlineImages"
                ),
                status: e.QuesShowAns1.status,
              }
            : "",
        QuesAns:
          e.QuesType !== "Paragraph"
            ? e.QuesAns.map((e) => {
                return {
                  txt: e.txt.replace(
                    /image\/inlineImages/g,
                    window.location.pathname.split("index.html")[0] +
                      "image/inlineImages"
                  ),
                  buttons: e.buttons,
                };
              })
            : "",
        QuesRightAns: e.QuesRightAns,
        index: e.index,
        Answer:
          userAnswer[currentQues] && userAnswer[currentQues][index]
            ? true
            : false,
        userAnswer:
          userAnswer[currentQues] &&
          userAnswer[currentQues][index] &&
          userAnswer[currentQues][index][0].answer
            ? userAnswer[currentQues][index][0].answer
            : null,
        numeric_type: e.numeric_type,
        QuesUsrAns: {
          isRightAnswer:
            userAnswer[currentQues] &&
            userAnswer[currentQues][index] &&
            userAnswer[currentQues][index][0].answer ===
              userAnswer[currentQues][index][0].right
              ? true
              : false,
          userAns:
            userAnswer[currentQues] && userAnswer[currentQues][index]
              ? userAnswer[currentQues][index][0].answer
              : null,
          modelAns:
            e.QuesType === "mcq"
              ? getAnswerFromRight(e.QuesAns, e.QuesRightAns, null)
              : e.QuesType === "essay"
              ? e.QuesShowAns1.txt
              : null,
        },
        breadcrumbs: exams.breadcrumbs,
        showAnserButton: exams.showAnserButton,
      };
      item.push(a);
    });
    return item;
  };

  const toggleModel = () => {
    // @ts-ignore
    dispatch(setBlur(!blur));
    // @ts-ignore
    dispatch(setModal(!modal));
  };

  const CloseModel = () => {
    // @ts-ignore
    dispatch(setBlur(false));
    // @ts-ignore
    dispatch(setModal(false));
  };

  const ViewBriefReport = () => {
    // localStorage.removeItem("userTime_" + params.id)
    // localStorage.removeItem("userAnswer_" + params.id)
    dispatch(setBlur(false));
    // @ts-ignore
    dispatch(setModal(false));
    // @ts-ignore
    setModelEssayEvaluation(true);
    // @ts-ignore
    window.timer = false;
  };

  const finishExam = () => {
    // @ts-ignore
    dispatch(
      setModalBotton([
        {
          text: config.config[questions.lang].yes,
          color: "text-white", // tail wind css color
          border: "border", //tail wind css border
          borderColor: "border-[#151048]", //tail wind css border
          background: "bg-[#151048]",
          action: ViewBriefReport,
        },
        {
          text: config.config[questions.lang].no,
          color: "text-[#151048]", // tail wind css color
          border: "border", //tail wind css border
          borderColor: "border-gray-500", //tail wind css border
          background: "bg-gray-200",
          action: CloseModel,
        },
      ])
    );

    // @ts-ignore
    dispatch(setModalTitle(questions.title));
    // @ts-ignore
    dispatch(setModalSubTitle(config.config[questions.lang].ConfirmFinishExam));
    dispatch(setModalBody(""));
    toggleModel();
  };

  const getAnswerFromRight = (answers, rightAnswer, userNswer) => {
    let a = {
      ar: ["أ", "ب", "جـ", "د", "و"],
      en: ["a", "b", "c", "d", "e"],
    };
    return answers[a[exams.lang].indexOf(rightAnswer)].txt;
  };

  const mathConfig = {
    loader: {
      load: ["ui/lazy"],
    },
    tex: {
      inlineMath: [
        ["$", "$"],
        ["\\(", "\\)"],
        ["\\(", "\\)"],
      ],
    },
  };

  const userRight = () => {
    var num = 0;
    userAnswer.map((e) => {
      if (e && e.type) {
        num = exams.marked === true ? num + e.userScore : num + 1;
      } else {
        if (e) {
          e.map((q) => {
            if (q != null) {
              if (q[0] != null) {
                num =
                  exams.marked === true
                    ? num + q[0].userScore
                    : num + q[0].score;
              }
            }
          });
        }
      }
    });
    return num;
  };

  function startTimerMain() {
    const duration = exams.duration;
    const pad = (n) => (n < 10 ? `0${n}` : n);
    const h = Math.floor(duration / 3600);
    const m = Math.floor(duration / 60) - h * 60;
    const s = Math.floor(duration - h * 3600 - m * 60);
    // @ts-ignore
    window.time = h + ":" + pad(m) + ":" + pad(s);

    // @ts-ignore
    dispatch(setTimer(h + ":" + pad(m) + ":" + pad(s)));
  }
  const gotoDivision = () => {
    navigate("/home");
  };

  const test_local_image = (imageUrl) => {
   
  };

  useEffect(() => {
    // featchContent()
  }, [isLoading]);


  const featchContent = async () => {
    // $("#loadImagesToLocal").html("");
    await Promise.resolve(ae.questions.map((quez) => {
        const pattern = new RegExp(`/image/${params.id}/inlineImages/.*\\.svg`, "g");
        if (quez.QuesType !== "Paragraph") {
            const a_text = (quez.QuesTxt             ).match(pattern);
            const b_text = (quez.QuesAns[0]?quez.QuesAns[0].txt:''  ).match(pattern);
            const c_text = (quez.QuesAns[1]?quez.QuesAns[1].txt:''  ).match(pattern);
            const d_text = (quez.QuesAns[2]?quez.QuesAns[2].txt:''  ).match(pattern);
            const e_text = (quez.QuesAns[3]?quez.QuesAns[3].txt:''  ).match(pattern);
            const f_text = (quez.QuesShowAns1?quez.QuesShowAns1.txt:'').match(pattern);
            if(a_text !== null){
                console.log(a_text);
                images.push(...a_text)
                let a = [...new Set(images)]
                setImages(a)
            }
        }else{
            const parent_text = (quez.QuesTxt         ).match(pattern);
            quez.list.map(e=>{
                const a_text = (e.QuesTxt             ).match(pattern);
                const b_text = (e.QuesAns[0]?e.QuesAns[0].txt:''  ).match(pattern);
                const c_text = (e.QuesAns[1]?e.QuesAns[1].txt:''  ).match(pattern);
                const d_text = (e.QuesAns[2]?e.QuesAns[2].txt:''  ).match(pattern);
                const e_text = (e.QuesAns[3]?e.QuesAns[3].txt:''  ).match(pattern);
                const f_text = (e.QuesShowAns1?e.QuesShowAns1.txt:'').match(pattern);
                if(a_text !== null){
                    console.log(a_text);
                    images.push(...a_text)
                    let a = [...new Set(images)]
                    setImages(a)
                }
            })
        }
    })).then(()=>{
        setTimeout(() => {
            console.log(images);
        }, 500);
    })
  };

  if (isLoading === true) {
    return (
      <div
        dir={lang === "ar" ? "rtl" : "ltr"}
        className={`w-full h-screen  p-0 bg-gray-100 flex flex-col items-center content-center justify-items-center font-[Almarai]'}  md:max-w-[875px] md:m-auto `}
      >
        <BackNavBar />
        <div className="flex w-screen h-full bg-[#001670] bg-opacity-60 fixed top-12 left-0 justify-center items-center align-middle content-center self-center z-50  md:max-w-[875px] md:m-auto  right-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-10 h-10 animate-spin stroke-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
            />
          </svg>
        </div>
      </div>
    );
  } else {
    return (
      <div
        dir={questions.lang === "ar" ? "rtl" : "ltr"}
        className={`w-full h-screen p-0 bg-gray-100 flex flex-col font-[Almarai] md:max-w-[875px] md:m-auto `}
      >
        <div id="loadImagesToLocal" className="hidden"></div>
        {accessable === true ? (
          activeLogin === false ? (
            <>
              <BackNavBar />

              {fullReport === false ? <Timer active={exams.timer} /> : <></>}
              <div
                dir={questions.lang === "ar" ? "rtl" : "ltr"}
                className={`bg-white w-full h-full ${
                  activeLogin ? "z-0 blur-sm" : ""
                } overflow-auto`}
              >
                <div
                  className={`min-h-full flex-col flex justify-between px-2 sm:px-10 pb-5`}
                >
                  {questions.questions[currentQues].QuesType === "Paragraph" ? (
                    <div className="w-full h-full py-4 flex flex-col">
                      <div className="w-full flex flex-col justify-start gap-4 relative">
                        <Title
                          quesNumber={currentQues + 1}
                          question={questions.questions[currentQues]}
                          bookmark={false}
                        />

                        <div
                          className={`h-fit max-h-[27vh] overflow-auto font-['roman'] rtl:font-['Droid'] border rounded-t-md rounded-b-[20px] border-gray-300 p-2 bg-white`}
                        >
                          <ResizeImage
                            size="rem"
                            type="paragraph"
                            text={questions.questions[currentQues].QuesTxt}
                          />
                        </div>
                        <div
                          id="paragraphBody"
                          className={`h-fit max-h-[50vh] overflow-auto border-t`}
                        >
                          <Paragraph
                            essayKey={uuidv4()}
                            index={questions.questions[currentQues].QuesID}
                            lang={questions.lang}
                            key={currentQues}
                            quesNumber={currentQues + 1}
                            question={questions.questions[currentQues]}
                          />
                          <div className="flex flex-col h-fit justify-center">
                            <Controllers lang={questions.lang} />
                            <Pagination
                              number={examCounter}
                              type={exams.questions}
                            />
                            {fullReport !== true ? (
                              <button
                                onClick={() => finishExam()}
                                className="w-full sm:w-52 m-auto border-[#151048] text-white bg-[#151048] border text-center py-2 px-4 rounded-full  font-['roman'] rtl:font-['Droid']"
                              >
                                {config.config[questions.lang].finishExam}
                              </button>
                            ) : (
                              <button
                                onClick={() => dispatch(setBriefReport(true))}
                                className="w-full sm:w-52 m-auto border-[#151048] text-white bg-[#151048] border text-center py-2 px-4 rounded-full  font-['roman'] rtl:font-['Droid']"
                              >
                                {config.config[questions.lang].briefRprtBtn}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="w-full pb-4 px-1 flex flex-col">
                      <div>
                        {questions.questions[currentQues].QuesType === "mcq" ? (
                          <MCQ
                            id={params.id}
                            lang={questions.lang}
                            key={currentQues}
                            quesNumber={currentQues + 1}
                            question={questions.questions[currentQues]}
                            answers={questions.questions[currentQues].QuesAns}
                          />
                        ) : (
                          <Essay
                            lang={questions.lang}
                            key={currentQues}
                            quesNumber={currentQues + 1}
                            question={questions.questions[currentQues]}
                            answers={questions.questions[currentQues].QuesAns}
                          />
                        )}
                      </div>
                    </div>
                  )}
                  {questions.questions[currentQues].QuesType !== "Paragraph" ? (
                    <div className="flex flex-col h-fit justify-center">
                      <Controllers lang={questions.lang} />
                      <Pagination number={examCounter} type={exams.questions} />
                      {fullReport !== true ? (
                        <button
                          onClick={() => finishExam()}
                          className="w-full sm:w-52 m-auto border-[#151048] text-white bg-[#151048] border text-center py-2 px-4 rounded-full  font-['roman'] rtl:font-['Droid']"
                        >
                          {config.config[questions.lang].finishExam}
                        </button>
                      ) : (
                        <button
                          onClick={() => dispatch(setBriefReport(true))}
                          className="w-full sm:w-52 m-auto border-[#151048] text-white bg-[#151048] border text-center py-2 px-4 rounded-full  font-['roman'] rtl:font-['Droid']"
                        >
                          {config.config[questions.lang].briefRprtBtn}
                        </button>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  <EssayEvaluation
                    active={
                      modelEssayEvaluation === true && fullReport === false
                    }
                    lang={exams.lang}
                  />
                  <FullReport
                    action={() => {}}
                    retray={true}
                    lang={exams.lang}
                    active={briefReport}
                    message={config.config[exams.lang].ExcelentRsltMsg}
                    total={actualNumber}
                    right={{
                      number: userRight(),
                      color: ["#C0E7BA", "#2FAE1D"],
                    }}
                  />
                  <StartExam
                    active={startExam}
                    action=""
                    lang={questions.lang}
                    timer={{
                      active: questions.timer,
                      time: questions.duration,
                    }}
                    number=""
                    title={questions.title}
                  />
                  {/* finish Model */}
                  <Modal active={modal} lang={exams.lang} />
                  <EssayModal active={essayModal} lang={exams.lang} />
                  <div className="h-10" />
                </div>
              </div>
            </>
          ) : (
            ""
          )
        ) : (
          <AccessibleScreen
            active={true}
            lang={lang}
            action={() => gotoDivision()}
          />
        )}
      </div>
    );
  }
};
