export const books = [
    {
        id: 319,
        name: 'Algebra and Analytic Solid Geometry',
        image: '/bg/icons/algebraAndGeometry.svg',
        division: 'mathematics',
        school: 'en',
        description: 'some text',
        sort:14,
        date:'2023/6/25',
        active: true
    },
    {
        id: 317,
        name: 'Differential & Integral Calculus',
        image: '/bg/icons/differentiationAndIntegration.svg',
        division: 'mathematics',
        school: 'en',
        description: 'some text',
        sort:10,
        date:'2023/6/25',
        active: true
    },
    {
        id: 318,
        name: 'Statics',
        image: '/bg/icons/statics.svg',
        division: 'mathematics',
        school: 'en',
        description: 'some text',
        sort:7,
        date:'2023/6/25',
        active: true
    },
    {
        id: 316,
        name: 'Dynamics',
        image: '/bg/icons/dynamics.svg',
        division: 'mathematics',
        school: 'en',
        description: 'some text',
        sort:12,
        date:'2023/6/25',
        active: true
    },
    {
        id: 327,
        name: 'Physics',
        image: '/bg/icons/physics.svg',
        division: 'science',
        school: 'en',
        description: 'some text',
        sort:5,
        date:'2023/6/25',
        active: true
    },
    {
        id: 327,
        name: 'Physics',
        image: '/bg/icons/physics.svg',
        division: 'mathematics',
        school: 'en',
        description: 'some text',
        sort:5,
        date:'2023/6/25',
        active: true
    },
    {
        id: 6,
        name: 'Chemistry',
        image: '/bg/icons/chemistry.svg',
        division: 'science',
        school: 'en',
        description: 'some text',
        sort:3,
        date:'2023/6/18',
        active: true
    },
    {
        id: 6,
        name: 'Chemistry',
        image: '/bg/icons/chemistry.svg',
        division: 'mathematics',
        school: 'en',
        description: 'some text',
        sort:3,
        date:'2023/6/18',
        active: true
    },
    {
        id: 328,
        name: 'Biology',
        image: '/bg/icons/biology.svg',
        division: 'science',
        school: 'en',
        description: 'some text',
        sort:13,
        date:'2023/6/25',
        active: true
    },
    {
        id: 305,
        name: 'الجبر والهندسة الفراغية',
        image: '/bg/icons/algebraAndGeometry.svg',
        division: 'mathematics',
        school: 'ar',
        description: 'some text',
        sort:14,
        date:'2023/6/25',
        active: true
    },
    {
        id: 313,
        name: 'التفاضل والتكامل',
        image: '/bg/icons/differentiationAndIntegration.svg',
        division: 'mathematics',
        school: 'ar',
        description: 'some text',
        sort:10,
        date:'2023/6/25',
        active: true
    },
    {
        id: 286,
        name: 'الاستاتيكا',
        image: '/bg/icons/statics.svg',
        division: 'mathematics',
        school: 'ar',
        description: 'some text',
        sort:7,
        date:'2023/6/25',
        active: true
    },
    {
        id: 287,
        name: 'الديناميكا',
        image: '/bg/icons/dynamics.svg',
        division: 'mathematics',
        school: 'ar',
        description: 'some text',
        sort:12,
        date:'2023/6/25',
        active: true
    },
    {
        id: 285,
        name: 'الفيزياء',
        image: '/bg/icons/physics.svg',
        division: 'science',
        school: 'ar',
        description: 'some text',
        sort:5,
        date:'2023/6/25',
        active: true
    },
    {
        id: 285,
        name: 'الفيزياء',
        image: '/bg/icons/physics.svg',
        division: 'mathematics',
        school: 'ar',
        description: 'some text',
        sort:5,
        date:'2023/6/25',
        active: true
    },
    {
        id: 312,
        name: 'الكيمياء',
        image: '/bg/icons/chemistry.svg',
        division: 'science',
        school: 'ar',
        description: 'some text',
        sort:3,
        date:'2023/6/18',
        active: true
    },
    {
        id: 312,
        name: 'الكيمياء',
        image: '/bg/icons/chemistry.svg',
        division: 'mathematics',
        school: 'ar',
        description: 'some text',
        sort:3,
        date:'2023/6/18',
        active: true
    },
    {
        id: 314,
        name: 'الأحياء',
        image: '/bg/icons/biology.svg',
        division: 'science',
        school: 'ar',
        description: 'some text',
        sort:13,
        date:'2023/6/25',
        active: true
    },
    {
        id: 282,
        name: 'Français',
        image: '/bg/icons/french.svg',
        division: 'all',
        school: 'all',
        description: 'some text',
        sort:2,
        date:'2023/6/12',
        active: true
    },
    {
        id: 277,
        name: 'اللغة العربية',
        image: '/bg/icons/arabic.svg',
        division: 'all',
        school: 'all',
        description: 'some text',
        sort:1,
        date:'2023/6/12',
        active: true
    },
    {
        id: 284,
        name: 'الچيولوچيا والعلوم البيئية',
        image: '/bg/icons/geology.svg',
        division: 'science',
        school: 'all',
        description: 'some text',
        sort:9,
        date:'2023/6/25',
        active: true
    },
    {
        id: 278,
        name: 'التاريخ',
        image: '/bg/icons/history.svg',
        division: 'literary',
        school: 'all',
        description: 'some text',
        sort:6,
        date:'2023/6/25',
        active: true
    },
    {
        id: 279,
        name: 'الجغرافيا',
        image: '/bg/icons/geography.svg',
        division: 'literary',
        school: 'all',
        description: 'some text',
        sort:4,
        date:'2023/6/18',
        active: true
    },
    {
        id: 283,
        name: 'الفلسفة وقضايا العصر',
        image: '/bg/icons/philosophy.svg',
        division: 'literary',
        school: 'all',
        description: 'some text',
        sort:15,
        date:'2023/6/25',
        active: true
    },
    {
        id: 280,
        name: 'English',
        image: '/bg/icons/english.svg',
        division: 'all',
        school: 'all',
        description: 'some text',
        sort:8,
        date:'2023/6/25',
        active: true
    },
    {
        id: 281,
        name: 'علم النفس والاجتماع',
        image: '/bg/icons/psychology.svg',
        division: 'literary',
        school: 'all',
        description: 'some text',
        sort:11,
        date:'2023/6/25',
        active: true
    }
]