import React, { useEffect, useState } from 'react';
import { useSignIn, useIsAuthenticated } from 'react-auth-kit'
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import langConfig from "../configuration/lang.json"
import config from "../sql/config.json"
import { PrivacyPolicy, TermsAndConditions } from '../pages';
import { books, governorates, schools, divisions } from '../data';
import AWS from 'aws-sdk';
import sha1 from 'crypto-js/sha1';
import $ from 'jquery'
import { setModal, setModalBody, setModalBotton, setModalSubTitle, setModalTitle, setUserLogin, setBlur } from '../store/actions';
import { v4 as uuidv4 } from "uuid";

export const Login = (props) => {
return <></>
}