import {
    SET_START_EXAM,
    SET_LANG,
    SET_BLUR,
    SET_MODAL,
    SET_CURRENT_QUES,
    EXAM_COUNTER,
    SET_QUESTIONS_LIST,
    SET_USER_ANSWER,
    SET_BRIEF_REPORT,
    SET_FULL_REPORT,
    SET_MODAL_TITLE,
    SET_MODAL_SUB_TITLE,
    SET_MODAL_BODY,
    SET_MODAL_BUTTON,
    SET_BOOKMARK,
    SET_ESSAY_MODAL,
    SET_ESSAY_MODAL_BODY,
    SET_ESSAY_MODAL_BUTTON,
    SET_ESSAY_MODAL_TITLE,
    SET_ESSAY_MODAL_USER,
    SET_ESSAY_SCORE,
    SET_TIMER,
    SET_EXAM_CONFIG,
    SET_USER_LOGIN,
    SET_ACTUAL_NUMBER
} from "./actions";
const id = window.location.pathname.split('/');
// console.log(id[3]);
let exams = []
if(id[3] && typeof parseInt(id[3]) === Number){
    exams = require('../data/exams/' + id[3] + '.json');
}else{
    exams= [{questions: []}]
}
const initialState = {
    startExam: true,
    lang: "ar",
    blur: false,
    modal: false,
    currentQues: 0,
    actualNumber: 0,
    examCounter: 0,
    questionsList: exams.questions,
    userAnswer: window.report||[],
    briefReport: false,
    fullReport: false,
    modalBody: '',
    modalTitle: '',
    modalSubTitle: '',
    modalBotton: [],
    bookmark: JSON.parse(localStorage.getItem("bookmark"))||[],
    essayModal: false,
    essayModalTitle: '',
    essayModalBody: '',
    essayModalButton: '',
    essayModalUser: '',
    essayScore: 0,
    timer: 0,
    examConfig: [],
    userLogin: false
}



function userAppReducer(state = initialState, action) {
    switch (action.type) {
        case SET_START_EXAM:
            return {
                ...state,
                startExam: action.payload
            }
        case SET_LANG:
            return {
                ...state,
                lang: action.payload
            }
        case SET_BLUR:
            return {
                ...state,
                blur: action.payload
            }
        case SET_MODAL:
            return {
                ...state,
                modal: action.payload
            }
        case SET_CURRENT_QUES:
            return {
                ...state,
                currentQues: action.payload
            }
        case EXAM_COUNTER:
            return {
                ...state,
                examCounter: action.payload
            }
        case SET_QUESTIONS_LIST:
            return {
                ...state,
                questionsList: action.payload
            }
        case SET_USER_ANSWER:
            return {
                ...state,
                userAnswer: action.payload
            }
        case SET_BRIEF_REPORT:
            return {
                ...state,
                briefReport: action.payload
            }
        case SET_FULL_REPORT:
            return {
                ...state,
                fullReport: action.payload
            }
        case SET_MODAL_BODY:
            return {
                ...state,
                modalBody: action.payload
            }
        case SET_MODAL_TITLE:
            return {
                ...state,
                modalTitle: action.payload
            }
        case SET_MODAL_SUB_TITLE:
            return {
                ...state,
                modalSubTitle: action.payload
            }
        case SET_MODAL_BUTTON:
            return {
                ...state,
                modalBotton: action.payload
            }
        case SET_BOOKMARK:
            return {
                ...state,
                bookmark: action.payload
            }
        case SET_ESSAY_MODAL:
            return {
                ...state,
                essayModal: action.payload
            }
        case SET_ESSAY_MODAL_TITLE:
            return {
                ...state,
                essayModalTitle: action.payload
            }
        case SET_ESSAY_MODAL_BODY:
            return {
                ...state,
                essayModalBody: action.payload
            }
        case SET_ESSAY_MODAL_BUTTON:
            return {
                ...state,
                essayModalButton: action.payload
            }
        case SET_ESSAY_MODAL_USER:
            return {
                ...state,
                essayModalUser: action.payload
            }
        case SET_ESSAY_SCORE:
            return {
                ...state,
                essayScore: action.payload
            }
        case SET_TIMER:
            return {
                ...state,
                timer: action.payload
            }
        case SET_EXAM_CONFIG:
            return {
                ...state,
                examConfig: action.payload
            }
        case SET_USER_LOGIN:
            return {
                ...state,
                userLogin: action.payload
            }
        case SET_ACTUAL_NUMBER:
            return {
                ...state,
                actualNumber: action.payload
            }
        default:
            return state
    }
}

export default userAppReducer;