//Pagination
import React,{useEffect,useState} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { setCurrentQues } from '../store/actions';
import { useParams } from 'react-router-dom';
import $ from 'jquery'

export const Pagination = (props) => {
    const params = useParams()
    const dispatch = useDispatch();
    // @ts-ignore
    const { currentQues, userAnswer, fullReport, briefReport, bookmark } = useSelector(state => state.userAppReducer);
    const [currentBookmark, setcurrentBookmark] = useState([]);

    const setCurentQues = (index) => {
        // @ts-ignore
        dispatch(setCurrentQues(index))
        // paragraphBody
        if($('#paragraphBody')){
            $('#paragraphBody').scrollTop(0,0)
        }
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
    useEffect(() => {
        setcurrentBookmark(bookmark.filter(e => e.lesson == params.id)||[])
    }, [props]);
    useEffect(() => {
        setcurrentBookmark(bookmark.filter(e => e.lesson == params.id)||[])
    }, [currentQues]);

    const checkIfParagraphHasEssay = (questions) => {
        for (var i = 0; i < questions.length; i++) {
            if (questions[i].QuesType === 'essay') {
                return true;
                break;
            }
        }
    }

    const checkIfParagraphEssayAnswerd = (questions) => {
        let essayQ = [];
        let essaA = [];
        for (var i = 0; i < questions.length; i++) {
            if (questions[i].QuesType === 'essay') {
                essayQ.push(i)
            }
        }
    }

    const checkIfParagraphAnswer = (index) => {
        let list = 0;
        if (userAnswer[index] && typeof userAnswer[index] == 'object') {
            userAnswer[index].map((e) => {
                if (e && e[0].score === e[0].userScore) {
                    list++
                }
            })
        }
        return list
    }


    const AnswerNumber = ({ index }) => {
        // console.log(userAnswer.filter(e=>e.index==index));
        let current_is_answerd = userAnswer.filter(e=>e!==null && e.index==index)[0];
        // console.log(current_is_answerd);
        return (
            <div className={`${(currentQues === index) ? 'w-10 h-10 border-2 border-[#151048] rounded-md flex  justify-center items-center align-middle' : 'a'}`}>
                <button
                    type='button'
                    onClick={() => setCurentQues(index)}
                    className={`${(currentQues === index) ? 'w-8 h-8' : 'w-10 h-10'} select-none 
                        ${(props.type[index].QuesType !== 'mcq') ?
                            (
                                (props.type[index].QuesType === 'Paragraph') ? (
                                    (checkIfParagraphAnswer(index) === (props.type[index].list).length) ? ('bg-[#288D57]') : (
                                        (checkIfParagraphAnswer(index) > 0) ? 'bg-[#f1964a]' : 'bg-[#AB2620]'
                                    )
                                ) :
                                    (
                                        (props.type[index].QuesType === 'essay') ? (
                                            (current_is_answerd && current_is_answerd.userScore >= (current_is_answerd.score / 2)) ?
                                                'bg-[#288D57]' :
                                                'bg-[#AB2620]'
                                        ) : ('bg-[#f1964a]')
                                    )
                            ) :
                            (
                                (current_is_answerd && (current_is_answerd.userScore === current_is_answerd.score || current_is_answerd.answer === current_is_answerd.right )) ?
                                    'bg-[#288D57]' :
                                    'bg-[#AB2620]'
                            )
                        } text-white font-['roman'] rtl:font-['ArabicEqu'] rounded-md flex  justify-center items-center align-middle relative`}>
                    <IconBox active={current_is_answerd?true:false} right={(current_is_answerd && (current_is_answerd.userScore >= (current_is_answerd.score / 2 ) || current_is_answerd.answer === current_is_answerd.right ))?true:false} />
                    <BookMarkIcon color="fill-white" color_active="fill-white" fill={(currentQues === index)?true:false} active={
                        (currentBookmark && currentBookmark[0] && currentBookmark[0].bookmark[index] === true)?true:false
                    }/>
                    {index + 1}
                </button>
            </div>
        )
   
    }


    const IconBox = ({right, active}) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" 
                className={`
                    w-5 h-5 border -top-2 -right-2 z-10 absolute bg-white rounded-full
                    ${(right===true)?'stroke-green-600':'stroke-red-600'}
                    ${(active===false)?'hidden':''}
                `}>
                {
                    (right==true)
                    ?(<path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />)
                    :(<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />)
                }
            </svg>
        )
    }


    const BookMarkIcon = ( {color, color_active, fill, active} ) => {
        return(
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" 
                className={`w-4 h-4 top-[-2px] z-10 absolute left-[-1px] 
                    ${(fill===true)?color:color_active} 
                    ${(active===true)?'flex':'hidden'}
                `}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z" />
            </svg>
        )
    }
    return (
        <div className="w-full flex flex-row justify-center flex-wrap gap-3  px-2 py-5">
            {
                Array.apply(null, { length: props.number }).map((element, index) => (
                    (fullReport === true && briefReport === false) ? (
                        <AnswerNumber key={index} index={index} />
                    ) : (
                        <button
                            key={index}
                            type='button'
                            onClick={() => setCurentQues(index)}
                            className={`w-10 h-10 relative select-none ${(currentQues === index) ? 'bg-[#151048] text-white' : ((userAnswer[index]) ? ' bg-opacity-10 text-[#151048] bg-[#151048]' : 'text-[#151048] bg-white')}
                            border-[#151048] font-['roman'] rtl:font-['ArabicEqu'] border rounded-md flex  justify-center items-center align-middle`}
                        >
                            <BookMarkIcon color="fill-white" color_active="fill-[#151048]" fill={(currentQues === index)?true:false} active={
                                (currentBookmark && currentBookmark[0] && currentBookmark[0].bookmark[index] === true)?true:false
                            }/>
                            {index + 1}
                        </button>
                    )
                ))
            }
        </div>
    );

}