import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider, RequireAuth } from 'react-auth-kit'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import { store } from './store'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home, 
  Subjects, 
  Lessons, 
  Exam, 
  NotFound, 
  BookMark, 
  TermsAndConditions, 
  ExamReport, 
  PrivacyPolicy,
  Users
} from './pages';
import refreshApi from './configuration/refreshApi';
import config from  "../package.json"
const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
<Provider store={store}>
    <React.StrictMode>
      <AuthProvider authType={'localstorage'}
        authName={'_auth'}
        refresh={refreshApi}
      >
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<App />} />
            <Route path="/home" element={<Home />} />
            <Route path="/subjects/:school/:division" element={<Subjects />} />
            <Route path="/lessons/:subjectID" element={<Lessons />} />
            <Route path="/exam/:subjectID/:id" element={<Exam />} />
            <Route path="/exam/:subjectID/:id/:index" element={<Exam />} />
            <Route path="/exam/:subjectID/:id/full-report" element={<ExamReport />} />
            <Route path="/exam/:subjectID/:id/:index/full-report" element={<ExamReport />} />
            <Route path="/exam/notfound" element={<NotFound />} />
            <Route path="/bookmark/:subjectID" element={<BookMark />} />
            <Route path="/terms&conditions" element={<TermsAndConditions />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            {
              (config.environment == true)?(<Route path="/users" element={<Users />} />):'< />'
            }
            <Route path="*" element={<NotFound />} />
            <Route component={NotFound} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </React.StrictMode>
  </Provider>
);

reportWebVitals();

