import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { NavBar, Login, AccessibleScreen } from "../components";
import { lessons as lessonsList, books } from "../data";
import langConfig from "../configuration/lang.json";
import { useIsAuthenticated } from "react-auth-kit";
import { setBlur, setUserAnswer } from "../store/actions";
import AWS from "aws-sdk";

export const Lessons = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  // console.log(searchParams);
  const dispatch = useDispatch();
  const { lang, userLogin } = useSelector((state) => state.userAppReducer);
  const [scoring, setScoring] = useState(
    JSON.parse(localStorage.getItem("scoring_" + params.subjectID)) || []
  );
  const [lessonsLd, setLessonId] = useState(lessonsList[params.subjectID]);
  const [accessable, setAccessable] = useState(false);
  const [scoreList, setScoreList] = useState([]);
  const [currentDivision, setCurrentDivision] = useState(
    localStorage.getItem("division")
  );
  const [retake, setRetake] = useState([]);
  useEffect(() => {
    dispatch(setBlur(true));
    getLastUserScore();
    checkAccessible();
  }, [params.subjectID]);

  const getLastUserScore = async () => {
    dispatch(
      setBlur(
        isAuthenticated() === false &&
          localStorage.getItem("userLogin") &&
          localStorage.getItem("userLogin") === false
          ? true
          : false
      )
    );
    setRetake(Array(lessonsList[params.subjectID].length).fill(false));
    getScoreFromServer();
  };

  const getScoreFromServer = async () => {
    // let userId = JSON.parse(localStorage.getItem('uuid')).id
    // console.log(userId);
    var scores = [];
    let items = [];
  };
  const LessonBox = ({ item }) => {
    var link = "";
    link = "/exam/" + params.subjectID + "/" + item.id;
    scoring[item.index] = JSON.parse(
      localStorage.getItem("userAnswer_" + item.id) || false
    );
    retake[item.index] = JSON.parse(
      localStorage.getItem("retake_" + item.id) || false
    );
    setScoring(scoring);
    setRetake(retake);
    return (
      <div
        key={item.id}
        className="bg-white h-14 border shadow-md rounded-lg flex flex-row gap-3 justify-start items-center overflow-hidden"
      >
        <button
          onClick={() =>
            goToFunction(
              item,
              link,
              JSON.parse(
                localStorage.getItem("userAnswer_" + item.id) ? true : false
              )
            )
          }
          className="w-full flex h-full items-center"
        >
          {
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className={`
                            felx mx-2 w-6 h-6 shrink-0
                            ${
                              scoring[item.index]
                                ? "stroke-white fill-[#151048]"
                                : "fill-white stroke-[#151048]"
                            }
                        `}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
              {scoring[item.index] ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              ) : (
                ""
              )}
            </svg>
          }
          <h1 className="w-full flex p-2 text-[#151048] font-[Almarai]">
            {item.name}
          </h1>
          {retake[item.index] === true ? (
            <div className="relative flex flex-row justify-end mx-2 w-32 border rounded-full h-3 bg-gray-200">
              <div className="w-4/12 bg-[#151048] rounded-full"></div>
            </div>
          ) : scoring[item.index] ? (
            <div className="relative flex flex-row justify-end mx-2 w-32 border rounded-full h-3 bg-gray-200">
              <div className="w-full bg-[#151048] rounded-full"></div>
            </div>
          ) : (
            ""
          )}
        </button>
      </div>
    );
  };
  const goToFunction = (item, link, score) => {
    const ae = require("../data/exams/" + item.id + ".json");
    if (score) {
      ae.userAnswer =
        JSON.parse(localStorage.getItem("userAnswer_" + item.id)) || [];
    }
    if (localStorage.getItem("retake_" + item.id) == "true") {
      navigate(link);
    } else {
      navigate(!score ? link : link + "/full-report");
    }
  };
  const gotoDivision = () => {
    navigate("/home");
  };
  const Notfound = () => {
    return (
      <div className="full w-full flex justify-center items-center min-h-[90vh] flex-col gap-9">
        <img
          src="/bg/notFound.svg"
          className="w-3/4"
          alt={langConfig[lang].notFound}
        />
        <p>{langConfig[lang].notFound}</p>
      </div>
    );
  };
  const checkAccessible = () => {
  };
  return (
    <div
      dir={lang === "ar" ? "rtl" : "ltr"}
      className="flex flex-col justify-start h-full w-full p-0 m-0 md:max-w-[875px] md:m-auto overflow-hidden"
    >
      {/* <Login current={true} active={activeLogin} /> */}
      <NavBar
        menu={false}
        qr={false}
        titleText={books.filter((book) => book.id == params.subjectID)[0].name}
        bookmarkIcon={false}
        title={true}
        id={params.subjectID}
        back={true}
        to={"/subjects/" + currentDivision}
      />
      <div className={`flex flex-col gap-3 w-full h-fit px-4 py-3`}>
        {lessonsList[params.subjectID].map((e, index) => (
          <LessonBox key={index} item={{ index: index, ...e }} />
        ))}
      </div>
    </div>
  );
};
