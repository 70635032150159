export const lessons = {
    6:[
        {
            id: 9958,
            name: 'Exam 1',
            description: '',
            link: '',
        },
        {
            id: 9959,
            name: 'Exam 2',
            description: '',
            link: '',
        },
        {
            id: 9960,
            name: 'Exam 3',
            description: '',
            link: '',
        }
    ],
    319:[
        {
            id: 9822,
            name: 'Exam 1',
            description: '',
            link: '',
        },
        {
            id: 9823,
            name: 'Exam 2',
            description: '',
            link: '',
        },
        {
            id: 9824,
            name: 'Exam 3',
            description: '',
            link: '',
        },
        {
            id: 9825,
            name: 'Exam 4',
            description: '',
            link: '',
        },
        {
            id: 9826,
            name: 'Exam 5',
            description: '',
            link: '',
        }
    ],
    317:[
        {
            id: 9811,
            name: 'Exam 1',
            description: '',
            link: '',
        },
        {
            id: 9812,
            name: 'Exam 2',
            description: '',
            link: '',
        },
        {
            id: 9813,
            name: 'Exam 3',
            description: '',
            link: '',
        },
        {
            id: 9814,
            name: 'Exam 4',
            description: '',
            link: '',
        },
        {
            id: 9815,
            name: 'Exam 5',
            description: '',
            link: '',
        }
    ],
    318:[
        {
            id: 9817,
            name: 'Exam 1',
            description: '',
            link: '',
        },
        {
            id: 9818,
            name: 'Exam 2',
            description: '',
            link: '',
        },
        {
            id: 9819,
            name: 'Exam 3',
            description: '',
            link: '',
        },
        {
            id: 9820,
            name: 'Exam 4',
            description: '',
            link: '',
        },
        {
            id: 9821,
            name: 'Exam 5',
            description: '',
            link: '',
        }
    ],
    316:[
        {
            id: 9806,
            name: 'Exam 1',
            description: '',
            link: '',
        },
        {
            id: 9807,
            name: 'Exam 2',
            description: '',
            link: '',
        },
        {
            id: 9808,
            name: 'Exam 3',
            description: '',
            link: '',
        },
        {
            id: 9809,
            name: 'Exam 4',
            description: '',
            link: '',
        },
        {
            id: 9810,
            name: 'Exam 5',
            description: '',
            link: '',
        }
    ],
    280:[
        {
            id: 10067,
            name: 'Exam 1',
            description: '',
            link: '',
        },
        {
            id: 10068,
            name: 'Exam 2',
            description: '',
            link: '',
        },
        {
            id: 10069,
            name: 'Exam 3',
            description: '',
            link: '',
        },
        {
            id: 10070,
            name: 'Exam 4',
            description: '',
            link: '',
        },
        {
            id: 10071,
            name: 'Exam 5',
            description: '',
            link: '',
        },
        {
            id: 10076,
            name: 'Exam 6',
            description: '',
            link: '',
        }
    ],
    327:[
        {
            id: 9829,
            name: 'Exam 1',
            description: '',
            link: '',
        },
        {
            id: 9830,
            name: 'Exam 2',
            description: '',
            link: '',
        },
        {
            id: 9831,
            name: 'Exam 3',
            description: '',
            link: '',
        }
    ],
    328:[
        {
            id: 10082,
            name: 'Exam 1',
            description: '',
            link: '',
        },
        {
            id: 10083,
            name: 'Exam 2',
            description: '',
            link: '',
        },
        {
            id: 10084,
            name: 'Exam 3',
            description: '',
            link: '',
        }
    ],
    305:[
        {
            id: 9780,
            name: 'الامتحان الأول',
            description: '',
            link: '',
        },
        {
            id: 9781,
            name: 'الامتحان الثانى',
            description: '',
            link: '',
        },
        {
            id: 9782,
            name: 'الامتحان الثالث',
            description: '',
            link: '',
        },
        {
            id: 9783,
            name: 'الامتحان الرابع',
            description: '',
            link: '',
        },
        {
            id: 9784,
            name: 'الامتحان الخامس',
            description: '',
            link: '',
        }
    ],
    313:[
        {
            id: 9795,
            name: 'الامتحان الأول',
            description: '',
            link: '',
        },
        {
            id: 9796,
            name: 'الامتحان الثانى',
            description: '',
            link: '',
        },
        {
            id: 9797,
            name: 'الامتحان الثالث',
            description: '',
            link: '',
        },
        {
            id: 9798,
            name: 'الامتحان الرابع',
            description: '',
            link: '',
        },
        {
            id: 9799,
            name: 'الامتحان الخامس',
            description: '',
            link: '',
        }
    ],
    286:[
        {
            id: 9733,
            name: 'الامتحان الأول',
            description: '',
            link: '',
        },
        {
            id: 9734,
            name: 'الامتحان الثانى',
            description: '',
            link: '',
        },
        {
            id: 9735,
            name: 'الامتحان الثالث',
            description: '',
            link: '',
        },
        {
            id: 9736,
            name: 'الامتحان الرابع',
            description: '',
            link: '',
        },
        {
            id: 9737,
            name: 'الامتحان الخامس',
            description: '',
            link: '',
        }
    ],
    287:[
        {
            id: 9751,
            name: 'الامتحان الأول',
            description: '',
            link: '',
        },
        {
            id: 9752,
            name: 'الامتحان الثانى',
            description: '',
            link: '',
        },
        {
            id: 9753,
            name: 'الامتحان الثالث',
            description: '',
            link: '',
        },
        {
            id: 9754,
            name: 'الامتحان الرابع',
            description: '',
            link: '',
        },
        {
            id: 9755,
            name: 'الامتحان الخامس',
            description: '',
            link: '',
        }
    ],
    285:[
        {
            id: 9768,
            name: 'الامتحان الأول',
            description: '',
            link: '',
        },
        {
            id: 9769,
            name: 'الامتحان الثانى',
            description: '',
            link: '',
        },
        {
            id: 9770,
            name: 'الامتحان الثالث',
            description: '',
            link: '',
        }
    ],
    312:[
        {
            id: 9790,
            name: 'الامتحان الأول',
            description: '',
            link: '',
        },
        {
            id: 9791,
            name: 'الامتحان الثانى',
            description: '',
            link: '',
        },
        {
            id: 9792,
            name: 'الامتحان الثالث',
            description: '',
            link: '',
        }
    ],
    314:[
        {
            id: 9800,
            name: 'الامتحان الأول',
            description: '',
            link: '',
        },
        {
            id: 9803,
            name: 'الامتحان الثانى',
            description: '',
            link: '',
        },
        {
            id: 9804,
            name: 'الامتحان الثالث',
            description: '',
            link: '',
        }
    ],
    282:[
        {
            id: 9724,
            name: 'Examen 1',
            description: '',
            link: '',
        },
        {
            id: 9725,
            name: 'Examen 2',
            description: '',
            link: '',
        },
        {
            id: 9726,
            name: 'Examen 3',
            description: '',
            link: '',
        },
        {
            id: 9727,
            name: 'Examen 4',
            description: '',
            link: '',
        },
        {
            id: 9728,
            name: 'Examen 5',
            description: '',
            link: '',
        }
    ],
    277:[
        {
            id: 10095,
            name: 'الامتحان الأول',
            description: '',
            link: '',
        },
        {
            id: 10096,
            name: 'الامتحان الثانى',
            description: '',
            link: '',
        },
        {
            id: 10097,
            name: 'الامتحان الثالث',
            description: '',
            link: '',
        },
        {
            id: 10098,
            name: 'الامتحان الرابع',
            description: '',
            link: '',
        },
        {
            id: 10099,
            name: 'الامتحان الخامس',
            description: '',
            link: '',
        }
    ],
    284:[
        {
            id: 9739,
            name: 'الامتحان الأول',
            description: '',
            link: '',
        },
        {
            id: 9740,
            name: 'الامتحان الثانى',
            description: '',
            link: '',
        },
        {
            id: 9741,
            name: 'الامتحان الثالث',
            description: '',
            link: '',
        }
    ],
    278:[
        {
            id: 10085,
            name: 'الامتحان الأول',
            description: '',
            link: '',
        },
        {
            id: 10086,
            name: 'الامتحان الثانى',
            description: '',
            link: '',
        },
        {
            id: 10087,
            name: 'الامتحان الثالث',
            description: '',
            link: '',
        },
        {
            id: 10088,
            name: 'الامتحان الرابع',
            description: '',
            link: '',
        },
        {
            id: 10089,
            name: 'الامتحان الخامس',
            description: '',
            link: '',
        }
    ],
    279:[
        {
            id: 10090,
            name: 'الامتحان الأول',
            description: '',
            link: '',
        },
        {
            id: 10091,
            name: 'الامتحان الثانى',
            description: '',
            link: '',
        },
        {
            id: 10092,
            name: 'الامتحان الثالث',
            description: '',
            link: '',
        },
        {
            id: 10093,
            name: 'الامتحان الرابع',
            description: '',
            link: '',
        },
        {
            id: 10094,
            name: 'الامتحان الخامس',
            description: '',
            link: '',
        }
    ],
    283:[
        {
            id: 10073,
            name: 'الامتحان الأول',
            description: '',
            link: '',
        },
        {
            id: 10074,
            name: 'الامتحان الثانى',
            description: '',
            link: '',
        },
        {
            id: 10075,
            name: 'الامتحان الثالث',
            description: '',
            link: '',
        }
    ],
    281:[
        {
            id: 10078,
            name: 'الامتحان الأول',
            description: '',
            link: '',
        },
        {
            id: 10079,
            name: 'الامتحان الثانى',
            description: '',
            link: '',
        },
        {
            id: 10080,
            name: 'الامتحان الثالث',
            description: '',
            link: '',
        }
    ],
}