import React, { useState, useEffect } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import config from "../sql/config.json";
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { setBlur, setCurrentQues, setBriefReport, setFullReport, setUserAnswer } from '../store/actions';
import {AwsScoreSubmit, AwsOldScore} from "./"

export const FullReport = (props) => {
  const params = useParams()
  const navigate = useNavigate();
  const exams = require('../data/exams/' + params.id + '.json')


  const { active, score, lang, right, wrong } = props;
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();
  const { essayScore, userAnswer, actualNumber } = useSelector(state => state.userAppReducer);
  const [fullScoreMain, setFullScoreMain] = useState(0)
  const [fullUserScore, setFullUserScore] = useState(0)
  const [questions, setQuestions] = useState(exams)
  const [reportMode, setReportMode] = useState(window.location.search)
  const [wrongAnswers, setWrongAnswers] = useState(0)
  // @ts-ignore
  const percentage = (partialValue, totalValue) => {
    return parseInt((100 * parseInt(partialValue)) / parseInt(totalValue));
  }

  useEffect(() => {
    if (exams.marked === true) {
      let a = 0;
      exams.questions.map(question => {
        if (question.QuesType != "Paragraph") {
          a = a + parseInt(question.QuesScore)
        } else {
          question.list.map(e => {
            a = a + parseInt(e.QuesScore)
          })
        }
      })
      setTotal(a)
    } else {
      let a = 0;
      exams.questions.map(question => {
        if (question.QuesType != "Paragraph") {
          a = a + 1
        } else {
          a = a + (question.list.length)
        }
      })
      setTotal(a)
    }

    let defualtScores = 0;
    let userScores = 0;
    Promise.all(
      exams.questions.map((q, index) => {
        if (q.QuesType === 'Paragraph') {
          q.list.map((e, key) => {
            defualtScores += parseInt(e.QuesScore);
            userScores += (userAnswer[index] && userAnswer[index][key] && userAnswer[index][key][0].userScore) ? parseInt(userAnswer[index][key][0].userScore) : parseInt(0)
          })
        } else {
          defualtScores += parseInt(q.QuesScore);
          userScores += (userAnswer[index] && userAnswer[index].userScore) ? parseInt(userAnswer[index].userScore) : parseInt(0)
        }
      })
    ).finally(() => {
      setFullScoreMain(parseInt(defualtScores))
      setFullUserScore(parseInt(userScores))
    }).catch((error) => {
    });
    checkWrongAnswer()
  }, [active])

  const checkScoreFinal = () => {
   
  }

  const HideBriefReport = () => {
    // @ts-ignore
    dispatch(setBlur(false))
    // @ts-ignore
    dispatch(setCurrentQues(0))
    // @ts-ignore
    dispatch(setBriefReport(false))
    // @ts-ignore
    dispatch(setFullReport(true))
  };

  const getFullTime = (duration) => {
    const pad = (n) => n < 10 ? `0${n}` : n;
    const h = Math.floor(duration / 3600);
    const m = Math.floor(duration / 60) - (h * 60);
    const s = Math.floor(duration - h * 3600 - m * 60);
    return pad(h) + ":" + pad(m) + ":" + pad(s)
  }


  const checkWrongAnswer = () =>{
    var num = 0;
    if(exams.marked === false){
      userAnswer.map(e => {
        if (e && e.type) {
          if(e.answer !== e.right && e.type === 'mcq'){
            num = num+1
          }else if (e.score !== e.userScore && e.type === 'essay'){
            num = num+1
          }
        } else {
          if (e) {
            e.map(q => {
              if (q != null) {
                if (q[0] != null) {
                  if(q[0].answer !== q[0].right&& q[0].type === 'mcq'){
                    num = num+1
                  }else if (q[0].score !== q[0].userScore && q[0].type === 'essay'){
                    num = num+1
                  }
                }
              }
            })
          }
        }
      })
    }
    setWrongAnswers(num)
  }

  if (active === true) {
    localStorage.removeItem('retake_' + params.id)
    return (
      <div className='w-screen h-full overflow-x-hidden justify-center md:items-center overflow-y-auto bg-white fixed top-0 left-0 flex flex-col  gap-8 py-10  z-40 pb-14 right-0 md:max-w-[875px] md:m-auto overflow-hidden'>
        <div className=" w-full h-fit shrink-0 flex justify-center  flex-col gap-10">
          <img className="text-center w-auto h-56" src="/bg/end_exam.svg" alt='End Exam' />
          {
            (exams.timer == true) ? (
              <div className="w-fit flex flex-row px-7 py-1 border rounded-lg m-auto mt-2 justify-between gap-10">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.658 17.861" className="h-5 w-5 flex">
                  <path d="M14.757,6.124,16.131,4.75a.92.92,0,0,0-1.3-1.3L13.456,4.823a1.17,1.17,0,0,0-.128.192,7.335,7.335,0,0,0-3.343-1.31V2.268H10.9a.916.916,0,1,0,0-1.832H7.237a.916.916,0,1,0,0,1.832h.916V3.706a7.332,7.332,0,1,0,6.476,2.5A1.007,1.007,0,0,0,14.757,6.124Zm-3.288,7.85a.88.88,0,0,1-.568.2.915.915,0,0,1-.714-.348l-1.832-2.29a.9.9,0,0,1-.2-.568V7.763a.916.916,0,0,1,1.832,0v2.885l1.63,2.043A.9.9,0,0,1,11.469,13.974Z" transform="translate(-1.741 -0.436)" fill="#151048" />
                </svg>
                <div className="font-[roman] rtl:font-[ArabicEqu] tracking-[2px]">
                  {
                    // @ts-ignore
                    getFullTime((localStorage.getItem("userTime_" + params.id)!=null)?parseInt(localStorage.getItem("userTime_" + params.id)):0)
                  }
                </div>
              </div>
            ) : ('')
          }
        </div>
        <div className="w-full flex flex-col justify-between gap-6">
          <div className="w-full flex flex-row-reverse justify-center items-center">
            <div className="w-[170px] h-[170px] rounded-full bg-white relative flex justify-center items-center content-center m-auto">
              <PieChart
                data={[
                  { value: ((right.number + essayScore)-wrongAnswers), color: right.color[1] },
                  { value: (total + essayScore) - (right.number + essayScore), color: right.color[0] },
                ]}
              />
              <div className="w-[155px] h-[155px] border-[8px] border-white  bg-[#288D57] text-white absolute rounded-full flex flex-col justify-center items-center content-center text-xl font-[roman] rtl:font-[ArabicEqu]">
                <span className='rtl:font-[ArabicEqu] text-center'>{(right.number + essayScore)-wrongAnswers} / {total}<br /><span className={`font-[roman] rtl:font-[Droid] ${(exams.marked !== true) ? 'text-sm' : ''}`}>
                  {
                    (exams.marked === true) ? config.config[props.lang].Degree : config.config[props.lang].briefRprtRghtQuesCount
                  }
                </span></span>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col-reverse md:flex-row justify-between items-end gap-4 px-10 mt-4 m-auto md:w-8/12">
            <button onClick={() => HideBriefReport()} type='button' className="w-full bg-[#151048] text-center select-none py-2 px-4 rounded-full text-white m-auto">{config.config[lang].fullRprtBtn}</button>
            {
              (props.retray === true) ? (
                <button onClick={() => {
                  localStorage.removeItem("userTime_" + params.id);
                  localStorage.removeItem("userAnswer_" + params.id);
                  window.location.reload(false);
                }} type='button' className="w-full text-[#151048] text-center select-none py-2 px-4 rounded-full bg-gray-300 m-auto">{config.config[lang].retakeExam}</button>
              ) : ((props.retray == 2) ? <button onClick={() => {
                localStorage.removeItem("userTime_" + exams.lession)
                localStorage.removeItem("userAnswer_" + exams.lession)
                setTimeout(()=>{
                  navigate(`/exam/${params.subjectID}/${params.id}`);
                },500)
              }} type='button' className="w-full text-[#151048] text-center select-none py-2 px-4 rounded-full bg-gray-300 m-auto">{config.config[lang].retakeExam}</button> : '')
            }
          </div>
          {
            /*
              (!localStorage.getItem("historicalDataSent"))?<AwsOldScore />:<AwsScoreSubmit answers={userAnswer} />
            */
          }
        </div>
      </div>
    );
  } else {
    return ''
  }
}