import React, { useState, useEffect } from "react";
import { isTablet, isDesktop } from "react-device-detect";
import $ from "jquery";

export const ResizeImage = (props) => {
  const [isLandscape, setIsLandscape] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  useEffect(() => {
    window.imagesCount = 0;
    resizeImage();
  }, [isLandscape]);

  useEffect(() => {
    function handleOrientationChange(mql) {
      setIsLandscape(mql.matches);
    }
    const mediaQueryList = window.matchMedia("(orientation: portrait)");
    mediaQueryList.addListener(handleOrientationChange);

    // Clean up the listener function when the component unmounts
    return () => {
      mediaQueryList.removeListener(handleOrientationChange);
    };
  }, []);

  const resizeImage = (text, type, size) => {
    $("#txt").html(text);
    let MainHeight = 30;
    let MainWidth = 30;
    let _Height = 12.43;
    let _Width = 98.34;
    var images = $("#txt").find("img");
    if (isDesktop) {
      MainHeight = 40;
      MainWidth = 340.19;
      for (let index = 0; index < images.length; index++) {
        var image_height = parseFloat(
          $(images[index]).css("height").replace("px", "")
        );
        var image_width = parseFloat(
          $(images[index]).css("width").replace("px", "")
        );
        var new_height = (MainHeight / _Height) * image_height * 0.063;
        var new_width = (MainWidth / _Width) * image_width * 0.063;
        if (type == "showAnswer") {
          $(images[index]).css("width", new_width * 1 + size);
        } else {
          $(images[index]).css("width", new_width * 0.52 + size);
        }
        let linkInamge = $(images[index]).attr("src");
        // $(images[index]).attr('src',(window.location.pathname).split('index.html')[0]+linkInamge)
        $(images[index]).attr(
          "src",
          linkInamge.replace(/http:\/\/192.168.3.108:3000\/uploads/g, "/image")
        );
        $(images[index]).css("height", "auto");
        $(images[index]).css("position", "relative");
      }
    } else if (isTablet) {
      MainHeight = 36.5;
      MainWidth = 276.89;
      for (let index = 0; index < images.length; index++) {
        var image_height = parseFloat(
          $(images[index]).css("height").replace("px", "")
        );
        var image_width = parseFloat(
          $(images[index]).css("width").replace("px", "")
        );
        var new_height = (MainHeight / _Height) * image_height * 0.063;
        var new_width = (MainWidth / _Width) * image_width * 0.063;
        if (isLandscape == true) {
          var new_width = new_width * 0.75;
        }
        $(images[index]).css("width", new_width * 0.63 + size);
        let linkInamge = $(images[index]).attr("src");
        // $(images[index]).attr('src',(window.location.pathname).split('index.html')[0]+linkInamge)
        $(images[index]).attr(
          "src",
          linkInamge.replace(/http:\/\/192.168.3.108:3000\/uploads/g, "/image")
        );

        $(images[index]).css("height", "auto");
        $(images[index]).css("position", "relative");
        $(images[index]).attr("resize", "isTablet");
      }
    } else {
      MainHeight = 23;
      MainWidth = 181.95;
      for (let index = 0; index < images.length; index++) {
        var image_height = parseFloat(
          $(images[index]).css("height").replace("px", "")
        );
        var image_width = parseFloat(
          $(images[index]).css("width").replace("px", "")
        );
        var new_width = (MainWidth / _Width) * image_width * 0.063;
        if (isLandscape == true) {
          var new_width = new_width * 1.1;
        }
        if (type == "showAnswer") {
          $(images[index]).css("width", new_width * 0.97 + size);
        } else {
          $(images[index]).css("width", new_width * 0.83 + size);
        }
        let linkInamge = $(images[index]).attr("src");
        // $(images[index]).attr('src',(window.location.pathname).split('index.html')[0]+linkInamge)
        $(images[index]).attr(
          "src",
          linkInamge.replace(/http:\/\/192.168.3.108:3000\/uploads/g, "/image")
        );
        $(images[index]).css("height", "auto");
        $(images[index]).css("position", "relative");
      }
    }

    let cont = $("#txt").find("div");
    for (let index = 0; index < cont.length; index++) {
      if ($(cont[index]).css("text-align") == "left") {
        $(cont[index]).attr("dir", "ltr");
      }
    }
    let cont_2 = $("#txt").find("p");
    for (let index = 0; index < cont_2.length; index++) {
      if ($(cont_2[index]).css("text-align") == "left") {
        $(cont_2[index]).attr("dir", "ltr");
      }
    }

    let content = $("#txt").html();
    return content;
  };
  return (
    <div className="w-full">
      <div id="txt" className="hidden font-['roman'] rtl:font-['Droid']"></div>
      <div
        onDragExit={props.lang == "ar" ? "ltr" : "rtl"}
        className={` ${
          props.fonts ? props.fonts : "font-[roman] rtl:font-[Droid]"
        } text-xl leading-[40px] sm:leading-[50px] text-left rtl:text-right w-full`}
        dangerouslySetInnerHTML={{
          __html: resizeImage(props.text, props.type, props.size),
        }}
      ></div>
    </div>
  );
};
