import langConfig from "../configuration/lang.json"

export const schools = [
    {
        id: 1,
        name: {
            ar: langConfig['ar'].ArabicSchool,
            en: langConfig['en'].ArabicSchool
        },
        param: "ar",
        type: 0
    },
    {
        id: 2,
        name: {
            ar: langConfig['ar'].EnglishSchool,
            en: langConfig['en'].EnglishSchool
        },
        param: "en",
        type: 0
    }
]