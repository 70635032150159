import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';import {
    setBlur,
    setModal,
    setModalBody,
    setModalTitle,
    setModalBotton,
    setModalSubTitle,
} from '../store/actions';
import { schools, divisions } from '../data';
import { NavBar, Modal, Login } from '../components';
import langConfig from "../configuration/lang.json"
import config from "../sql/config.json";
import { useIsAuthenticated } from 'react-auth-kit'
import { PrivacyPolicy, TermsAndConditions } from './';

export const Home = (props) => {
    const dispatch = useDispatch();
    const isAuthenticated = useIsAuthenticated()
    const history = useNavigate();;
    const { lang, modal, blur, userLogin } = useSelector(state => state.userAppReducer);
    const [optionsList, setOptionsList] = useState([])
    const [options, setOptions] = useState([])
    const [schoolListActive, setSchoolListActive] = useState(false)
    const [divisionsListActive, setDivisionsListActive] = useState(false)
    const [loading, setLoading] = useState(false);
    const [divisionsList, setDivisionsList] = useState(divisions)
    const [schoolList, setSchoolList] = useState(schools)
    const [isLogined, setIsLogined] = useState(false)
    const [checkLogin, setCheckLogin] = useState(false)
    const [activeLogin, setActiveLogin] = useState((isAuthenticated() === false && localStorage.getItem("userLogin") && localStorage.getItem("userLogin") === false) ? true : false);
    const [privacy, setPrivacy] = useState(false)
    const [tems, setTems] = useState(false)
    const [agreementTerms, setAgreementTerms] = useState(false)
    const [agreementPrivacy, setAgreementPrivacy] = useState(false)

    useEffect(() => {
        dispatch(setBlur(false))
        dispatch(setModal(false))
        const divi = localStorage.getItem('division');
        if(divi){
            const localDivi = divi.split('/') 
            options[1] = divisionsList.filter(item=>item.param === localDivi[1])[0];
            options[0] = schoolList.filter(item=>item.param === localDivi[0])[0];
            setOptions(options)
        }
    }, [])


    const optionModaleFunction = (txt, list) => {
        list[0].title = txt;
        setOptionsList(list)
        if (txt === 'schoolList') {
            setSchoolListActive(!schoolListActive)
        } else if (txt === 'divisionsList') {
            setDivisionsListActive(!divisionsListActive)
        }
    }

    const optionSelected = (props) => {
        const opt = options
        // console.log(props.type);
        opt[props.type] = props
        setOptions(opt)
        setSchoolListActive(false)
        setDivisionsListActive(false)
    }

    const saveMyCriteria = () => {
        // dispatch(setBlur(true))
        console.log(options);
        // dispatch(setModalTitle(langConfig[lang].alert))
        // dispatch(setModalSubTitle(langConfig[lang].confirmCriteria.replace('####',options[0].name[lang]).replace('#####',options[1].name[lang])))
        // dispatch(setModalBotton(buttonsListForModelFinish))
        // dispatch(setModal(true))
        localStorage.setItem('division',options[0].param + "/" + options[1].param)
        history('/subjects/' + options[0].param + "/" + options[1].param)
    }

    const buttonsListForModelFinish = [
        {
            text: langConfig[lang].Next,
            color: 'text-white', // tail wind css color
            border: 'border', //tail wind css border
            borderColor: 'border-[#151048]', //tail wind css border
            background: 'bg-[#151048]',
            action: ()=>history('/subjects/' + options[0].param + "/" + options[1].param),
        },
        {
            text: config.config[lang].BackExam,
            color: 'text-white', // tail wind css color
            border: 'border', //tail wind css border
            borderColor: 'border-[#151048]', //tail wind css border
            background: 'bg-[#151048]',
            action: ()=>closeModel(),
        }
    ];

    const closeModel = () => {
        // @ts-ignore
        dispatch(setBlur(false))
        // @ts-ignore
        dispatch(setModal(false))
    };

    return (
        <div dir={(lang === "ar") ? 'rtl' : 'ltr'} className={`w-full h-screen p-0 bg-gray-100 flex flex-col justify-start} md:max-w-[875px] md:m-auto overflow-hidden`}>
            {/* <RequireAuth loginPath={'/login'}></RequireAuth> */}
            {/* <Login current={false} active={activeLogin} /> */}
            <div className={`${activeLogin?'blur-sm':''}`}>

                <NavBar menu={true} qr={false} back={false} title={true} titleText={
                    langConfig[lang].SubjectPageTitle
                } />
                {
                    (checkLogin === true)?(<div className="w-full h-full min-h-[30vh] flex flex-row justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 animate-spin">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                        </svg>
                    </div>):(
                        (!loading) ? (<>
                            <div className={`flex ${(blur === true) ? 'blur-sm' : ''} w-full h-[90vh] px-4 py-6  overflow-auto`}>
                                <div className="w-full flex flex-col py-3 px-6 justify-between">
                                    <div className="flex w-full flex-col gap-0 md:max-w-screen-sm md:m-auto">
                                        <img className={`h-auto w-[160px] mb-10 flex m-auto `} alt="GPS education" src="./bg/GPS-Logo.svg" />
                                        <div className={`w-full h-fit flex-col gap-2 justify-start mt-2`}>
                                            <h3 className="text-lg text-[#151048] font-[Almarai] font-bold mb-2">
                                                الدراسة
                                            </h3>
                                            <div className="flex w-full flex-col gap-1">
                                                <button onClick={() => optionModaleFunction('schoolList', schoolList)} className={`
                                                    flex flex-row justify-between p-2 border bg-[#151048] text-white
                                                    ${(schoolListActive === true)?'rounded-t-xl':'rounded-xl'}
                                                `}>
                                                    <b className={` ${(!options[0]) ? 'text-gray-300' : ''} font-[Almarai] font-normal`}>{(!options[0]) ? langConfig['ar'].schoolLang : options[0].name['ar']}</b>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        {
                                                            (schoolListActive === true) ? (
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                            ) : (
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                            )
                                                        }
                                                    </svg>

                                                </button>
                                                {
                                                    (schoolListActive === true) ? (
                                                        <div className={`flex flex-col gap-2 justify-start p-2 border rounded-b-xl divide-y divide-white h-fit max-h-[30vh] overflow-auto -mt-1 bg-gray-300 bg-opacity-60`}>
                                                            {
                                                                schoolList.map((e, index) =>
                                                                    <button
                                                                        key={index}
                                                                        onClick={() => optionSelected(e)}
                                                                        className="flex w-full py-1 px-2 font-[Almarai] text-[#151048]"
                                                                    >{e.name['ar']}</button>
                                                                )
                                                            }
                                                        </div>
                                                    ) : ''
                                                }
                                            </div>
                                        </div>
                                        {/* start School divisions */}
                                        <div className={`w-full h-fit flex-col gap-2 justify-start mt-4`}>
                                            <h3 className="text-lg text-[#151048] font-[Almarai] font-bold mb-2">
                                                الشعبة
                                            </h3>
                                            <div className="flex w-full flex-col gap-1">
                                                <button onClick={() => optionModaleFunction('divisionsList', divisionsList)} className={`
                                                    flex flex-row justify-between p-2 border bg-[#151048] text-white
                                                    ${(divisionsListActive === true)?'rounded-t-xl':'rounded-xl'}
                                                `}>
                                                    <b className={` ${(!options[1]) ? 'text-gray-300' : ''} font-[Almarai] font-normal`}>{(!options[1]) ? langConfig['ar'].yourDivision : options[1].name['ar']}</b>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        {
                                                            (divisionsListActive === true) ? (
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                            ) : (
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                            )
                                                        }
                                                    </svg>

                                                </button>
                                                {
                                                    (divisionsListActive === true) ? (
                                                        <div className={`flex flex-col gap-2 justify-start p-2 border rounded-b-xl divide-y divide-white h-fit max-h-[30vh] overflow-auto -mt-1 bg-gray-300 bg-opacity-60`}>
                                                            {
                                                                divisionsList.map((e, index) =>
                                                                    <button
                                                                        key={index}
                                                                        onClick={() => optionSelected(e)}
                                                                        className="flex w-full py-1 px-2 font-[Almarai]"
                                                                    >{e.name['ar']}</button>
                                                                )
                                                            }
                                                        </div>
                                                    ) : ''
                                                }
                                            </div>
                                        </div>
                                        {/* end divisions */}
                                    </div>
                                    <div className="flex w-full flex-col gap-5 md:max-w-screen-sm md:m-auto">
                                        <div className="flex w-full flex-row gap-3 justify-start items-center">
                                            <div className="flex flex-row gap-1 w-fit shrink-0">
                                                <input id="agreementTerms" type='checkbox' checked={agreementTerms} onClick={()=>setAgreementTerms(!agreementTerms)}  class="w-6 h-6 text-[#151048] bg-[#151048] ring-offset-[#151048] ring-[#151048] border-gray-300 rounded " />
                                            </div>
                                            <label for="agreementTerms" className="font-[Almarai]">
                                                أوافق على
                                                <button type="button" onClick={()=>{setTems(true)}} className="font-[Almarai] text-blue-500 w-fit underline px-2">{langConfig['ar'].agreementNote[1]}</button>
                                            </label>
                                        </div>
                                        <div className="flex w-full flex-row gap-3 justify-start items-center">
                                            <div className="flex flex-row gap-1 w-fit shrink-0">
                                                <input id="agreementPrivacy" type='checkbox' checked={agreementPrivacy} onClick={()=>setAgreementPrivacy(!agreementPrivacy)}  class="w-6 h-6 text-[#151048] bg-[#151048] ring-offset-[#151048] ring-[#151048] border-gray-300 rounded " />
                                            </div>
                                            <label for="agreementPrivacy" className="font-[Almarai]">
                                                أوافق على
                                                <button type="button" onClick={()=>{setPrivacy(true)}} className="font-[Almarai] text-blue-500  w-fit underline px-2">{langConfig['ar'].agreementNote[3]}</button>
                                            </label>
                                        </div>
                                        {
                                            ((options[0] && options[1] && agreementPrivacy && agreementTerms)) ? (
                                                <button
                                                    onClick={() => saveMyCriteria()}
                                                    className={`${(options[0] && options[1]) ? 'bg-[#151048] text-white border-0' : 'border border-[#151048] text-[#151048] bg-white mt-'} font-[Almarai] rounded-full text-md w-full p-3 my-4  md:max-w-screen-sm md:m-auto`}
                                                >{langConfig[lang].Continue}</button>
                                            ) : <div
                                                className={`text-center text-[#151048] bg-gray-300 font-[Almarai] rounded-full text-md w-full p-3 my-4  md:max-w-screen-sm md:m-auto`}
                                            >{langConfig[lang].Continue}</div>
                                        }
                                    </div>
                                </div>
                            </div></>
                        ) : ('')
                    )
                }
                {/* <Modal active={modal} lang={lang} /> */}
            </div>
            {
                    (tems || privacy )?(
                        <div className="absolute top-0 left-0 h-screen w-screen z-50 bg-white overflow-auto">
                        {
                            (tems === true)?(
                                <TermsAndConditions  nav={false} close={()=>setTems(false)} />
                            ):''
                        }
                        {
                            (privacy === true)?(
                                <PrivacyPolicy  nav={false} close={()=>setPrivacy(false)} />
                            ):''
                        }
                        </div>
                    ):''
                }
        </div>
    )

}
