import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, Link } from 'react-router-dom';
import { books, divisions, lessons } from '../data';
import { NavBar, Login, AccessibleScreen } from '../components';
import langConfig from "../configuration/lang.json"
import { useIsAuthenticated } from 'react-auth-kit'
import { setBlur } from '../store/actions';



export const Subjects = (props) => {
    const params = useParams()
    const isAuthenticated = useIsAuthenticated()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { lang, blur, userLogin } = useSelector(state => state.userAppReducer);
    const [subjectsList, setSubjectsList] = useState([])
    const [mainBooks, setMainBooks] = useState(books);
    const [activeLogin, setActiveLogin] = useState((isAuthenticated() === false && localStorage.getItem("userLogin") || !localStorage.getItem("userLogin") || localStorage.getItem("userLogin") === false) ? true : false);
    const [loading, setLoading] = useState(false);
    const [accessable, setAccessable] = useState(false);
    const [currentDivision, setCurrentDivision] = useState(localStorage.getItem("division"))


    useEffect(() => {
        checkDivision()
        dispatch(setBlur((isAuthenticated() === false && localStorage.getItem("userLogin") || !localStorage.getItem("userLogin") || localStorage.getItem("userLogin") === false) ? true : false))
        const list = []
        mainBooks.map(item => {
            if ((item.school === 'all' || item.school === params.school) && (item.division === 'all' || item.division === params.division)) {
                list.push({ ...item })
            }
        })
        setSubjectsList(list.sort((a, b) => a.sort - b.sort))
        
    }, [params])

    const checkDivision = () => {
        if (currentDivision) {
            if (currentDivision === `${params.school}/${params.division}`) {
                setAccessable(true)
            } else {
                setAccessable(false)
            }
        } else {
        }
    }


    const SubjectBox = ({ item }) => {
        const givenDate = new Date(item.date);
        const today = new Date();
        const diffInTime = givenDate.getTime() - today.getTime();
        const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24)) + 1;
        //  console.log(item.date,diffInDays);
        return (
            (item.active === true && diffInDays <= 0) ? (
                <Link to={'/lessons/' + item.id}>
                    <div className="bg-white border shadow-md rounded-lg p-2 flex flex-row gap-5 justify-start items-center">
                        <img src={item.image} alt={item.name} className="flex w-20 h-20 shrink-0" />
                        <div className="w-full flex flex-col h-full gap-2  justify-start items-start content-start overflow-auto">
                            <h1 className="text-[18px] font-bold text-[#151048] font-[Almarai] text-start">{item.name}</h1>
                            {
                                (lessons[item.id]) ? <p className="text-sm text-gray-400 font-[Almarai]"><span className="font-[ArabicEqu]">{lessons[item.id].length} </span>{langConfig[lang].exams}</p> : ''
                            }
                        </div>
                    </div>
                </Link>
            ) : (
                <div className="bg-white border shadow-md rounded-lg p-2 flex flex-row gap-5 justify-start items-center relative z-0">
                    <img src={item.image} alt={item.name} className="flex w-20 h-20 shrink-0" />
                    <div className="w-full flex flex-col h-full gap-2  justify-start items-start content-start overflow-auto">
                        <h1 className="text-[18px] font-bold text-[#151048] font-[Almarai] text-start">{item.name}</h1>
                        {
                            (lessons[item.id]) ? <p className="text-sm text-gray-400 font-[Almarai]"><span className="font-[ArabicEqu]">{lessons[item.id].length} </span>{langConfig[lang].exams}</p> : ''
                        }
                    </div>
                    <div className="bg-gray-400 rounded-full absolute bottom-3 left-3 px-3 py-1 text-[12px] text-white">
                        سيتوفر في <span dir="rtl" className="font-[ArabicEqu]">{item.date}</span>
                    </div>
                </div>
            ))
    }

    const gotoDivision = () => {
        navigate("/subjects/" + currentDivision)
    }
    return (
        <div dir={(lang === "ar") ? 'rtl' : 'ltr'} className={`flex flex-col justify-start h-full w-full p-0 m-0 md:max-w-[875px] md:m-auto overflow-hidden`}>
            {/* <Login current={true} active={activeLogin} /> */}
            <NavBar menu={true} qr={false} back={false} title={true} titleText={
                langConfig[lang].SubjectPageTitle
            } />
            <div className={`flex flex-col gap-3 w-full h-fit px-4 py-3`}>
                {
                    subjectsList.map((e, index) => <SubjectBox key={index} item={e} />)
                }
                <div className="h-8"></div>
            </div>
        </div>
    )

}