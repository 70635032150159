import React, { useEffect, useState } from 'react';

import { Login} from "./components"
import { useIsAuthenticated } from 'react-auth-kit'
import { useNavigate } from 'react-router-dom';

function App(props) {
  const navigate = useNavigate()
  const isAuthenticated = useIsAuthenticated()
  const [activeLogin, setActiveLogin] = useState(false);
  const [startAnimation, setStartAnimation] = useState(false)

  const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
	if (path) {
		navigate.replace(path);
	}

  useEffect(() => {
    setTimeout(() => {
      setStartAnimation(true)
    }, 500);
    setTimeout(() => {
        navigate('/home')
    }, 4000);
  }, [])


  return (
    <div className={`flex flex-col w-full relative pt-[15vh]`}>
      {/* <Login active={activeLogin} to={"/home"} /> */}
      <div className={`flex w-full h-fit justify-center flex-col gap-6  items-center`}>
        <img className={`h-auto w-[200px] block ease-in-in duration-500 delay-100 ${(startAnimation === false) ? 'opacity-0 -mt-10' : 'opacity-1 -mt-0'}`} alt="GPS education" src="./bg/GPS-Logo.svg" />
        <div className="h-10"></div>
        <img className={`h-auto w-[200px] block ease-in-in duration-500 delay-500 ${(startAnimation === false) ? 'opacity-0 -mt-10' : 'opacity-1 -mt-0'}`} alt="المعاصر" src="./bg/logo_1.svg" />
        <img className={`h-auto w-[200px] block ease-in-in duration-500 delay-700 ${(startAnimation === false) ? 'opacity-0 -mt-10' : 'opacity-1 -mt-0'}`} alt="الإمتحان" src="./bg/logo_2.svg" />
        <img className={`h-auto w-[200px] block ease-in-in duration-500 delay-1000 ${(startAnimation === false) ? 'opacity-0 -mt-10' : 'opacity-1 mt-3'}`} alt='el-moasser' src="./bg/logo_3.svg" />
      </div>
    </div>
  )
}



export default App;
